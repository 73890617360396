import React, { useEffect } from "react";
import Link from "next/link";
import img from "../assets/img/home/Z images/Ride Hailing.webp";
import Router from "next/router";
const NotFoundPage = () => {
  useEffect(() => {
    Router.push(Router.asPath);
  }, []);
  return (
    <div>
      <section
        className="error-section  bg-dark min-vh-100 w-100 d-flex flex-column justify-content-center"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-6">
              <div className="error-page-content-wrap">
                <h2 className="error-404 text-warning">404</h2>
                <h1 className="display-5 fw-bold">Page Not Found</h1>
                <p className="lead">
                  Oops! You’ve wandered into the digital unknown. Looks like the
                  page you seek is taking a break. But worry not, we’ll get you
                  back on track. Click the go back home to restart your journey.
                </p>
                <Link href="/" passHref>
                  <a className="btn btn-green text-white mt-4">Go Back Home</a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 mt-5 d-none d-md-block d-lg-block">
              <div className="hero-img position-relative circle-shape-images">
                <img
                  src={img.src}
                  alt="hero img"
                  className="img-fluid position-relative z-5"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default NotFoundPage;